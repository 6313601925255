import React, { useState, useEffect, useContext } from "react";
import { graphql, Link } from 'gatsby';
import Layout from "../components/layout";
import FluidImage from "../components/fluidImage";
import WPLink from "../components/wpLink";
import Seo from 'gatsby-plugin-wpgraphql-seo';
import ThemeContext from '../context/ThemeContext';
import * as styleSheet from "./contact.module.scss";

const ContactTemplate = (props) => {

	const { location } = useContext(ThemeContext);
	const [ nearestLoc, setNearestLoc ] = useState(null);
	const gFormID = 2;

	const {
		data: {
			contact,
			contact: {
				title,
				ACFContact: {
					contact: {
						left: {
							blurb,
							insurancesHeading,
							requestAppointmentLink
						},
						right: {
							introContent: {
								blurb: introBlurb,
								heading: introHeading
							},
							contactContent: content,
							email: contactEmail,
							call: contactCall,
							visit: contactVisit,
							showContactForm: showForm,
              contactFormHeading: formHeading = 'Contact Us',
						}
					}
				}
			},
			options: {
				headerSection: {
					ACFHeaderOptions: {
						mainNavigationSettings: {
							headerLogo
						} 
					}
				}

			},
			insurance: {
				nodes: insurances
			},
			locations: {
				nodes: locations
			},
			introBGMobile,
			introBGTablet,
			introBGDesktop,
			introBGXL,
			appointmentPage
		}
	} = props;

	useEffect(() => {
		function sortByDistance() {
			let locationList = [];
	
			locations.forEach((wpLocation, i) => {
				const distance = getDistanceFromLatLonInMi(wpLocation.birdeyeLocation.location.lat, wpLocation.birdeyeLocation.location.lng, location.lat, location.lng);
				wpLocation.distance = distance;
				locationList.push(wpLocation);
			});
	
			locationList.sort(function (a, b) {
				var keyA = a.distance,
					keyB = b.distance;
				// Compare the 2 dates
				if (keyA < keyB) return -1;
				if (keyA > keyB) return 1;
				return 0;
			});
			function getDistanceFromLatLonInMi(lat1, lon1, lat2, lon2) {
				var R = 6371; // Radius of the earth in km
				var dLat = deg2rad(lat2 - lat1);  // deg2rad below
				var dLon = deg2rad(lon2 - lon1);
				var a =
					Math.sin(dLat / 2) * Math.sin(dLat / 2) +
					Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
					Math.sin(dLon / 2) * Math.sin(dLon / 2)
					;
				var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
				var d = R * c; // Distance in km
				var miles = d * 0.621371;
				return miles;
			}
	
			function deg2rad(deg) {
				return deg * (Math.PI / 180)
			}
	
			// apply sorted list to state
			setNearestLoc(locationList[0]);
		}
		if (location) {
			sortByDistance();
		}
	}, [location, locations]);

	return (
		<Layout>
			<Seo post={contact} />
			<div className={styleSheet.top}>
				<div className={styleSheet.left}>
					<div className={styleSheet.lefttop}>
						{ headerLogo && <Link to="/" className={styleSheet.logolink}><FluidImage image={headerLogo} passedClass={styleSheet.logo} loading={"eager"} /></Link>}
						<h1 className={styleSheet.contactname} dangerouslySetInnerHTML={{__html: title}}></h1>
					</div>
					<div className={styleSheet.leftbottom}>
						{ blurb && <div className={styleSheet.contactheading} dangerouslySetInnerHTML={{__html: blurb}}></div> }
						{ requestAppointmentLink && <WPLink link={requestAppointmentLink} passedClass={styleSheet.request} /> }
						{ !requestAppointmentLink && appointmentPage && appointmentPage.nodes.length > 0 && <Link to={appointmentPage.nodes[0].link} className={styleSheet.request}>Request appointment</Link> }
					</div>
				</div>
				<div className={styleSheet.right}>
					{ introBGMobile && <FluidImage image={introBGMobile} passedClass={styleSheet.bgmobile} loading={"eager"} /> }
					{ introBGTablet && <FluidImage image={introBGTablet} passedClass={styleSheet.bgtablet} loading={"eager"} /> }
					{ introBGDesktop && <FluidImage image={introBGDesktop} passedClass={styleSheet.bgdesktop} loading={"eager"} /> }
					{ introBGXL && <FluidImage image={introBGXL} passedClass={styleSheet.bgxl} loading={"eager"} /> }
					{ headerLogo && <Link to="/" className={styleSheet.logolink}><FluidImage image={headerLogo} passedClass={styleSheet.logo} loading={"eager"} /></Link>}
					<h1 className={styleSheet.contactname} dangerouslySetInnerHTML={{__html: title}} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in"></h1>
					{ introHeading && <div className={styleSheet.heading} dangerouslySetInnerHTML={{__html: introHeading}} data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease-in"></div> }
					{ introBlurb && <div className={styleSheet.blurb} dangerouslySetInnerHTML={{__html: introBlurb}} data-sal="slide-up" data-sal-delay="400" data-sal-easing="ease-in"></div> }
				</div>
			</div>
			<div className={styleSheet.bottom}>
				<div className={styleSheet.left}>
					<div className={styleSheet.insurance}>
						<span className={styleSheet.heading}>{ insurancesHeading ? insurancesHeading : 'We accept the following insurance.' }</span>
						<div className={styleSheet.insurancebottom}>
							{ insurances && insurances.map( (insurance, index) => {
								const image = insurance.ACFInsurance.logo;
								return image ? <FluidImage key={`insuranceimg-${index}`} image={image} passedClass={styleSheet.insuranceimage} /> : null;
							})}
						</div>
					</div>
				</div>
				<div className={styleSheet.right}>
					<div className={styleSheet.contactcontent}>
						<div className={styleSheet.contentleft}>
							{ content && <div className="wpcontent" dangerouslySetInnerHTML={{__html: content}}></div> }
							<div className={styleSheet.linkcards}>
								<div className={styleSheet.linkcard}>
									{ contactEmail.emailHeadline && <span className={styleSheet.title} dangerouslySetInnerHTML={{__html: contactEmail.emailHeadline }}></span>}
									{ contactEmail.emailContent && <div dangerouslySetInnerHTML={{__html: contactEmail.emailContent}}></div> }
								</div>
								<div className={styleSheet.linkcard}>
									{ contactCall.callHeadline && <span className={styleSheet.title} dangerouslySetInnerHTML={{__html: contactCall.callHeadline }}></span>}
									{ contactCall.callContent && <div dangerouslySetInnerHTML={{__html: contactCall.callContent}}></div> }
								</div>
								<div className={styleSheet.linkcard}>
									{ contactVisit.visitHeadline && <span className={styleSheet.title} dangerouslySetInnerHTML={{__html: contactVisit.visitHeadline }}></span>}
									{ contactVisit.visitContent && <div dangerouslySetInnerHTML={{__html: contactVisit.visitContent}}></div> }
								</div>
							</div>
							{showForm && (
                <div className={styleSheet.formWrap}>
                  <h2 className={styleSheet.formHeading}>{formHeading}</h2>
                  {nearestLoc?.databaseId && (
                    <iframe 
                      title="Gravity Form" 
                      src={`${process.env.GATSBY_WP_BASE_URL}/gfembed?f=` + gFormID + '&current_location=' + nearestLoc.databaseId + '&current_state=' + nearestLoc?.ACFLocation?.state} 
                      width="100%" height="885" frameBorder="0" className="gfiframe"></iframe>
                  )}
                </div>
              )}
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export const query = graphql`query ($id: String!) {
  contact: wpPage(id: {eq: $id}) {
    id
    title
    ACFContact {
      contact {
        left {
          blurb
          insurancesHeading
          requestAppointmentLink {
            title
            url
            target
          }
        }
        right {
          introContent {
            blurb
            heading
          }
          contactContent
          email {
            emailHeadline
            emailContent
          }
          call {
            callHeadline
            callContent
          }
          visit {
            visitHeadline
            visitContent
          }
					showContactForm
					contactFormHeading
        }
      }
    }
    seo {
      title
      metaDesc
      focuskw
      metaKeywords
      metaRobotsNoindex
      metaRobotsNofollow
      opengraphTitle
      opengraphDescription
      opengraphImage {
        altText
        sourceUrl
        srcSet
      }
      twitterTitle
      twitterDescription
      twitterImage {
        altText
        sourceUrl
        srcSet
      }
      canonical
      cornerstone
      schema {
        articleType
        pageType
        raw
      }
    }
  }
  options: wp {
    headerSection {
      ACFHeaderOptions {
        mainNavigationSettings {
          headerLogo {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 420, layout: CONSTRAINED)
              }
              publicURL
              extension
            }
          }
        }
      }
    }
  }
  appointmentPage: allWpPage(
    filter: {template: {templateName: {eq: "Appointments Page"}}}
  ) {
    nodes {
      link
    }
  }
  arrowBlue: file(name: {eq: "arrow_blue"}) {
    childImageSharp {
      gatsbyImageData(width: 9, layout: FIXED)
    }
  }
  introBGMobile: file(name: {eq: "patientinfobg"}) {
    childImageSharp {
      gatsbyImageData(width: 699, layout: CONSTRAINED)
    }
  }
  introBGTablet: file(name: {eq: "patientinfobg"}) {
    childImageSharp {
      gatsbyImageData(width: 550, layout: CONSTRAINED)
    }
  }
  introBGDesktop: file(name: {eq: "patientinfobg"}) {
    childImageSharp {
      gatsbyImageData(width: 840, layout: CONSTRAINED)
    }
  }
  introBGXL: file(name: {eq: "patientinfobg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  insurance: allWpInsurance {
    nodes {
      ACFInsurance {
        logo {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 420, layout: CONSTRAINED)
            }
            publicURL
            extension
          }
        }
      }
    }
  }
	locations: allWpLocation(sort: {fields: title, order: ASC}) {
		nodes {
			id
			title
			link
			databaseId
			birdeyeLocation {
				location {
					lat
					lng
				}
			}
			ACFLocation {
				state
			}
		}
	}
}
`;

export default ContactTemplate;
